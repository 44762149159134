
import { defineComponent } from "vue";
import ResultGridView from "@/components/Pos/ResultGridView.vue";
import Button from "primevue/button";
import POSPartsTable from "@/components/Pos/POSPartsTable.vue";
import InputText from "primevue/inputtext";
import TreeSelect from "primevue/treeselect";
import MultiSelect from "primevue/multiselect";
import Divider from "primevue/divider";
import InputSwitch from "primevue/inputswitch";
import Search from "@/components/Search.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ProductDialog from "./ProductDialog.vue";
import Dialog from "primevue/dialog";
import { mapActions, mapGetters, mapState } from "vuex";
import { FilterMatchMode } from "primevue/api";
import Utils from "@/utility/utils";
import Breadcrumb from "primevue/breadcrumb";
import RackService from "@/services/RackService";
import Racks from "@/components/Racks/Racks.vue";
import { LisItems } from "@/types/salesorder";
import EventBus, { AppEvents } from "@/utility/EventBus";
import { MenuItem } from "primevue/menuitem";
import { BREAKPOINT } from "@/utility/global";

import { FDICT_SO } from "@/utility/fdicts/so";
import { cloneDeep } from "lodash";
import { SellAsTypes } from "@/types/controls/price";

const rackService = new RackService();

export default defineComponent({
  name: "Pos",
  components: {
    Button,
    POSPartsTable,
    Search,
    ResultGridView,
    InputText,
    LoadingSpinner,
    TreeSelect,
    MultiSelect,
    Divider,
    ProductDialog,
    Racks,
    Breadcrumb,
    Dialog,
    InputSwitch,
  },
  props: {
    companyCode: {
      type: String,
      default: "",
    },
  },
  async created() {
    if (window.innerWidth > BREAKPOINT) {
      this.currentScreenSize = "lg";
    } else {
      this.currentScreenSize = "sm";
    }

    this.getPriceControl({ client: this.getClient, refresh: true });

    this.getMrkControl({
      client: this.getClient,
      selectedCode: this.companyCode || this.getSelectedCoCode,
      refresh: true,
    }).finally(() => {
      this.getPricesByCode();
    });

    this.partIdFields = this.controlFieldValues("PARTS", "0");
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeFilters);
    EventBus.off(AppEvents.PosFocusPartSearch);
  },
  mounted() {
    window.addEventListener("resize", this.resizeFilters);

    EventBus.on(AppEvents.PosFocusPartSearch, () => {
      this.autoFocusInput();
    });

    if (this.getCustomer?.cust_id) {
      rackService
        .getRackById(this.getClient, this.getCustomer.cust_id, "", "")
        .then((res: any) => {
          if (res.rack_items && res.rack_items.length > 0) {
            this.racks = res.rack_items[0].part_id_items;
            let rackStatusArray = [];
            rackStatusArray = this.racks?.map((part_id: any) => {
              return part_id.status;
            });

            if (rackStatusArray && rackStatusArray.length > 0) {
              this.showRacksButton = rackStatusArray.includes("O");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.autoFocusInput();

    if (this.getSelectedCategory) {
      this.handleCategoriesChange({ [this.getSelectedCategory]: true });
      this.selectedTreeCategories = { [this.getSelectedCategory]: true };
    }
  },
  computed: {
    ...mapState(["pos"]),
    ...mapGetters({
      getFilters: "control/getFilterItems",
      getCats: "control/getCategoryItems",
      getParts: "pos/getParts",
      getOrder: "pos/getCurrentOrder",
      getRegister: "pos/getRegister",
      getCustomer: "pos/getCustomer",
      getPriceStrategy: "pos/getPriceStrategy",
      getAutoAddParts: "pos/getAutoAddParts",
      getClient: "session/getClient",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
      isPriceCodeLocked: "priceControl/isPriceCodeLocked",
      controlFieldValues: "fdict/controlFieldValues",
      getScanSettings: "scan/getScanSettings",
      getQuickScan: "scan/getQuickScan",
      shippingAddress: "pos/getShippingAddress",
      getSalesOrder: "pos/getSalesOrder",
      posPartStatus: "mrkControl/posPartStatusList",
      getFdict: "fdict/getFdict",
      getSelectedFiltersOptions: "pos/getSelectedFiltersOptions",
      getSelectedCategory: "pos/getSelectedCategory",
      getPosShowCategoryFilter: "mrkControl/posShowCategoryFilter",
      getPosShowKeywordSearch: "mrkControl/posShowKeywordSearch",
      getCustomFields: "fdict/customFields",
      posMaintainCartSort: "mrkControl/posMaintainCartSort",
      posForceSamePartQtyIncrement: "mrkControl/posForceSamePartQtyIncrement",
      getPosClearCategoryOnSearch: "mrkControl/posClearCategoryOnSearch",
      getOldSalesOrder: "pos/getOldSalesOrder",
    }),
    showFilters() {
      return this.getFilters.length > 0 || this.getPosShowCategoryFilter;
    },
    breadCrumbHome(): MenuItem {
      return { label: "Category", command: this.handleCommand };
    },
    codeArray(): string[] {
      const arr = [];
      if (this.getCustomer?.code) {
        arr.push(this.getCustomer.code);
      }
      if (this.getCustomer?.cust_id !== this.getCustomer?.code) {
        arr.push(this.getCustomer.cust_id);
      }
      return arr;
    },
    buttonCategories(): any {
      const cat = this.getCategoryChildren(this.getSelectedCategory);
      if (cat?.subcategories) {
        return cat.subcategories.filter(
          (cat: any) =>
            cat.description
              ?.toLowerCase()
              .includes(this.filterCategories.toLowerCase()) ||
            cat.category
              ?.toLowerCase()
              .includes(this.filterCategories.toLowerCase()),
        );
      } else if (this.getSelectedCategory) {
        if (
          this.getCats.find((e: any) => e.category === this.getSelectedCategory)
        ) {
          return this.getCats.filter(
            (cat: any) =>
              cat.description
                ?.toLowerCase()
                .includes(this.filterCategories.toLowerCase()) ||
              cat.category
                ?.toLowerCase()
                .includes(this.filterCategories.toLowerCase()),
          );
        } else {
          return [];
        }
      } else {
        return this.getCats.filter(
          (cat: any) =>
            cat.description
              ?.toLowerCase()
              .includes(this.filterCategories.toLowerCase()) ||
            cat.category
              ?.toLowerCase()
              .includes(this.filterCategories.toLowerCase()),
        );
      }
    },
    smallFilterOptionsComputed(): any[] {
      let options = this.getFilters.map((elem: any) => ({
        key: elem.label,
        label: elem.label,
        icon: "pi pi-list",
        children: elem.tags.map((tag: any) => ({
          key: tag.tag,
          label: tag.label,
        })),
      }));
      return options;
    },
    isPriceLocked(): boolean {
      // CO 2-21-24 - Removing price lock for now until we can get the correct logic.
      // if(this.getCustomer && this.getCustomer.code) {
      //   return this.isPriceCodeLocked(this.getCustomer.code);
      // }
      // return true;
      return false;
    },
    customFieldsLineItems(): any {
      return this.getCustomFields("SO", FDICT_SO.LIS);
    },
    treeSelectCategories(): any {
      const buildTree = (categories: any, path: number[] = []) => {
        return categories.map((category: any) => {
          let newPath: number[] = [...path, category.category];
          return {
            key: category.category,
            label: category.description,
            children: category.subcategories
              ? buildTree(category.subcategories, newPath)
              : [],
            path: newPath,
          };
        });
      };
      return buildTree(this.getCats, []);
    },
  },

  methods: {
    ...mapActions({
      addPartToOrder: "pos/addPartToOrder",
      updatePartQty: "pos/updatePartQty",
      removeFromOrder: "pos/removeFromOrder",
      addRack: "pos/addRack",
      fetchPrices: "pos/fetchPrices",
      addNotification: "notification/add",
      getMrkControl: "mrkControl/getMrkControl",
      getPriceControl: "priceControl/getPriceControl",
      setQuickScan: "scan/setQuickScan",
      setControlFilters: "control/setFilters",
      setLastItemChanged: "pos/setLastItemChanged",
      setSelectedFiltersOptions: "pos/setSelectedFiltersOptions",
      clearSelectedFiltersOptions: "pos/clearSelectedFiltersOptions",
      setSelectedCategory: "pos/setSelectedCategory",
      validateSalesOrder: "pos/validateSalesOrder",
    }),
    handleShowPos() {
      if (this.windowWidth > BREAKPOINT) {
        return;
      }
      this.showPos = !this.showPos;
    },
    handleCommand(event: any) {
      event.originalEvent.preventDefault();
      if (event?.item?.code) {
        this.handleCrumbClick(event.item.code);
      } else {
        this.partSearch = [];
        this.selectedTreeCategories = null;
        this.setSelectedCategory("");
        this.getPricesByCode();
      }
    },
    priceMetaData(): string {
      let filters = this.getSelectedFiltersOptions.map((elem: any) => {
        return {
          [elem.label]: elem.value?.map((tag: any) => tag.tag),
        };
      });

      let categories = this.partSearch.map((cat: any) => {
        return cat.code;
      });

      const metaData = {
        filters,
        categories: categories,
      };

      return JSON.stringify(metaData);
    },
    removeMultiselectChip(event: any) {
      if (
        event?.originalEvent?.srcElement?.className ===
          "p-multiselect-token-icon pi pi-times-circle" ||
        event?.originalEvent?.srcElement?.className?.baseVal ===
          "p-icon p-multiselect-token-icon" ||
        event?.originalEvent?.srcElement?.farthestViewportElement?.nodeName ===
          "svg"
      ) {
        this.getFilteredParts();
      }
      this.setSelectedFiltersOptions(this.getSelectedFiltersOptions);
    },
    switchResultView(isList: boolean) {
      this.isResultsListView = isList;
    },
    async addItem(part: any) {
      const originalOrder = cloneDeep(this.getOldSalesOrder);
      let validateId = "";

      const index = this.getSalesOrder.lis_items.findIndex((item: LisItems) => {
        const itemPrice = parseFloat(item.li_prices || "0");
        const partPrice = parseFloat(part.price || "0");
        if (part.sell_as === SellAsTypes.ADD_TO_NEW_LINE) {
          return false;
        } else if (this.posForceSamePartQtyIncrement) {
          return item.li_parts === part.part_number;
        } else {
          return item.li_parts === part.part_number && itemPrice === partPrice;
        }
      });

      if (index >= 0) {
        const quantity = this.getSalesOrder.lis_items[index].li_order_qtys;
        const newQuantity = (parseFloat(quantity) + part.quantity).toString();
        this.getSalesOrder.lis_items[index].li_order_qtys = newQuantity;
        if (
          this.getSalesOrder.lis_items[index].li_sched_dates_items?.length > 0
        ) {
          this.getSalesOrder.lis_items[
            index
          ].li_sched_dates_items[0].li_sched_qtys = newQuantity;
        } else {
          this.getSalesOrder.lis_items[index].li_sched_dates_items = [
            {
              li_sched_dates: this.getSalesOrder.date,
              li_sched_qtys: newQuantity,
            },
          ];
        }

        const lineItem = this.getSalesOrder.lis_items[index];
        validateId = lineItem.lis;
        if (this.posMaintainCartSort) {
          this.getSalesOrder.lis_items[index] = lineItem;
        } else {
          this.getSalesOrder.lis_items.splice(index, 1);
          this.getSalesOrder.lis_items.unshift(lineItem);
        }
      } else {
        if (part.quantity > 0) {
          const date = this.getSalesOrder.date;

          let disc = 0.0;
          if (this.getCustomer.disc) {
            disc = parseFloat(this.getCustomer.disc) / 100;
          }

          const lineItemCustomFields = {} as any;
          Object.keys(this.customFieldsLineItems).map((field: any) => {
            lineItemCustomFields[field.json_name] = "";
            const name = this.customFieldsLineItems[field].dict_name
              .replaceAll(".", "_")
              .toLowerCase();
            lineItemCustomFields[name] = part[name] ? part[name] : "";
          });

          if (this.getSelectedCategory.length > 0) {
            lineItemCustomFields["posSelectedCategory"] =
              this.getSelectedCategory;
          }

          const listPrice = parseFloat(part.price) || 0.0;
          const discPrice = (listPrice * disc).toFixed(4);

          const id = Utils.getNewSalesOrderLineItemId(this.getSalesOrder);
          validateId = id;
          const partIdFields = this.controlFieldValues("PARTS", "0");
          const partIdValues = {} as any;
          partIdFields.forEach((field: any) => {
            partIdValues[field.json_name] = part[field.json_name] ?? "";
          });

          const newLineItem = {
            lis: id,
            li_parts: part.part_number,
            li_order_qtys: part.quantity.toString(),
            li_prices: part.price.toString(),
            li_descs: part.desc,
            li_discs: this.getCustomer.disc,
            li_disc_amts: discPrice,
            li_taxables: part.taxable,
            wrap_desc: part.desc,
            li_sched_dates_items: [
              {
                li_sched_dates: date,
                li_sched_qtys: part.quantity.toString(),
              },
            ],
            sell_qty: part.sell_qty,
            li_notes: "",
            avail_qty: part.avail_qty,
            average_cost: part.average_cost,
            um: part.um,
            fractions: part.fractions,
            custom_fields: lineItemCustomFields,
            ...partIdValues,
            // This seems potentially problematic if custom field names conflict with base li properties.
            ...lineItemCustomFields,
          };

          if (this.posMaintainCartSort) {
            this.getSalesOrder.lis_items = [
              ...this.getSalesOrder.lis_items,
              newLineItem,
            ];
          } else {
            this.getSalesOrder.lis_items = [
              newLineItem,
              ...this.getSalesOrder.lis_items,
            ];
          }
        }
      }
      await this.setLastItemChanged(part.part_number);

      await this.validateSalesOrder({
        oldSalesOrder: originalOrder,
        metaData: {
          validate_only: "Y",
          validate_reason: "LIS_ITEM",
          validate_id: validateId,
        },
      });

      this.$emit("line-item-changed");

      if (this.getScanSettings.scanMode) {
        //return focus to part search
        this.autoFocusInput();
      }
    },
    updatePrice(event: any, part: any) {
      if (event && event.value != part.quantity) {
        part.quantity = event.value;
      }

      const std_price = this.getStdPrice(part);
      const code_price = this.getCodePrice(part);
      if (code_price) {
        part["price"] = code_price;
      } else {
        part["price"] = std_price;
      }
    },
    onIsSearchingParts(event: boolean) {
      this.displayRacks = false;
      this.isSearchingParts = event;
    },
    populateSearchOptions() {
      this.firstItem = 0;
      let myFiltersObject = { filters: [] };
      if (this.getCustomer) {
        if (this.codeArray) {
          Utils.filterObjectAdd(
            myFiltersObject,
            "CODE",
            "NOT",
            this.codeArray as any,
          );
          Utils.filterObjectAdd(myFiltersObject, "STD.QTY", "IS", "");
        }
      }
      this.searchOptions = myFiltersObject;
    },
    getPriceSearchResult(result: any) {
      this.isSearchingParts = false;
      this.currentSearch = result.search;
      this.parts = result.parts;
      this.updateParts();
      // Logic to add part if search only returns 1 item. Based on parts with a valid code/std price.
      if (
        this.parts.length === 1 &&
        (this.getAutoAddParts || result.isScanned)
      ) {
        const cachedPart = this.partSearchCache.find(
          (e) => e.partSearch === result.search,
        );
        if (cachedPart) {
          cachedPart.value = result.parts;
          this.parts[0].quantity *= cachedPart.count;
          cachedPart.count = 0;
        }

        this.addItem(this.parts[0]);
      } else {
        // only cache searches returning one result (parts searched by id, for scanning)
        this.partSearchCache = this.partSearchCache.filter(
          (e) => e.partSearch !== result.search,
        );
      }
    },
    getCodePrice(price: any) {
      const quantity = price.quantity || 1;
      const lowerCaseCodeArray = this.codeArray.map((s) => s.toLowerCase());
      const code_prices = price.code_items?.filter((item: any) =>
        lowerCaseCodeArray.includes(item.code.toLowerCase()),
      );
      const cust_code = this.getCustomer.code?.toLowerCase();
      const cust_id = this.getCustomer.cust_id?.toLowerCase();
      let price_value = "";
      if (code_prices && code_prices.length > 0) {
        const code_price = code_prices.find((item: any) => {
          const code = item.code.toLowerCase();
          return code === cust_code;
        });
        const cust_price = code_prices.find((item: any) => {
          const code = item.code.toLowerCase();
          return code === cust_id;
        });

        if (code_price) {
          price_value = this.getPriceBasedOnQty(
            code_price.code_price_items,
            quantity,
          );
        } else if (cust_price) {
          price_value = this.getPriceBasedOnQty(
            cust_price.code_price_items,
            quantity,
          );
        }
      }
      if (price_value) {
        return price_value;
      }
      return "";
    },
    getPriceBasedOnQty(code_price_items: any, qty: number): string {
      let price = 0;
      if (code_price_items) {
        code_price_items.forEach((item: any) => {
          if (!item.code_qty || (item.code_qty && item.code_qty >= qty)) {
            const numericCodePrice = parseFloat(item.code_price);

            if (numericCodePrice > price) {
              price = numericCodePrice;
            }
          }
        });
      }
      return price.toString();
    },
    highestCodePrice(data: any, qty: number) {
      // loop through the items, return the highest code_price where the Qty >= qty passed
      let highestPrice = 0;
      if (!Array.isArray(data)) {
        data = [data];
      }

      data.forEach((element: any) => {
        if (element.code_price_items) {
          element.code_price_items.forEach((item: any) => {
            if (!item.code_qty || (item.code_qty && item.code_qty >= qty)) {
              const numericCodePrice = parseFloat(item.code_price);

              if (numericCodePrice > highestPrice) {
                highestPrice = numericCodePrice;
              }
            }
          });
        }
      });
      return highestPrice.toString();
    },
    lowestCodePrice(data: any, qty: number) {
      // loop through the items, return the lowest code_price where the Qty >= qty passed
      let lowestPrice = Infinity;
      if (!Array.isArray(data)) {
        data = [data];
      }
      data.forEach((element: any) => {
        if (element.code_price_items) {
          element.code_price_items.forEach((item: any) => {
            if (!item.code_qty || (item.code_qty && item.code_qty >= qty)) {
              const numericCodePrice = parseFloat(item.code_price);

              if (numericCodePrice < lowestPrice) {
                lowestPrice = numericCodePrice;
              }
            }
          });
        }
      });
      return lowestPrice.toString();
    },
    updateParts() {
      if (this.parts) {
        this.parts = this.parts
          .map((part) => {
            const std_price = this.getStdPrice(part);
            const code_price = this.getCodePrice(part);
            if (code_price) {
              part["price"] = code_price;
            } else {
              part["price"] = std_price;
            }
            part["quantity"] = parseFloat(part.sell_qty) || 1;
            return part;
          })
          .filter((item) => {
            return item.price != "";
          });
      }
    },
    getStdPrice(price: any) {
      const quantity = price.quantity || 1;
      let price_value = "";
      if (price.std_price_items) {
        if (price.std_price_items.length === 1) {
          price_value = price.std_price_items[0].std_price;
        } else {
          price.std_price_items.forEach((std_item: any) => {
            const std_quantity = parseFloat(std_item.std_qty);
            if (quantity >= std_quantity) {
              price_value = std_item.std_price;
            }
          });
        }

        // If there is no valid price with quantitys default to first option.
        if (price_value === "") {
          price_value = price.std_price_items[0].std_price;
        }
      }
      if (price_value) {
        return price_value;
      }
      return "0";
    },
    getPricesByCode() {
      this.clearCategory();

      this.displayRacks = false;
      this.currentSearch = "";
      this.populateSearchOptions();
      if (this.getCustomer) {
        this.isSearchingParts = true;
        this.callFetchPrices()
          .then((resp: any) => {
            this.totalRecords = resp.total_records_found
              ? parseInt(resp.total_records_found) + 1
              : 0;
            this.parts = resp.parts;
            this.updateParts();
            if (resp.filters) {
              this.setControlFilters(resp.filters);
            }
          })
          .finally(() => {
            this.isSearchingParts = false;
          });
      }
    },
    handleCrumbClick(cat: any) {
      this.selectedTreeCategories = { [cat]: true };
      this.onIsSearchingParts(true);
      this.currentSearch = "";
      this.callFetchPrices()
        .then((resp: any) => {
          this.totalRecords = resp.total_records_found
            ? parseInt(resp.total_records_found) + 1
            : 0;
          this.parts = resp.parts;
          if (resp.filters) {
            this.setControlFilters(resp.filters);
          }
        })
        .finally(() => {
          this.isSearchingParts = false;
        });
      this.setSelectedCategory(cat);
      let newBreadcrumbs: any[] = [];
      for (let cat of this.partSearch) {
        newBreadcrumbs.push({
          label: cat.label,
          code: cat.code,
          command: this.handleCommand,
        });
        if (cat.code === this.getSelectedCategory) {
          this.partSearch = newBreadcrumbs;
          break;
        }
      }
    },
    searchCategory(data: any, firstItem = 0, rangeEnd = 100) {
      if (this.partSearch.length !== 0) {
        if (
          this.buttonCategories.find(
            (e: any) =>
              e.category === this.partSearch[this.partSearch.length - 1].code,
          )
        ) {
          this.partSearch.pop();
        }
      }

      this.populateSearchOptions();
      this.displayRacks = false;

      if (!data.subcategories) {
        this.showCategoriesDialog = false;
      }

      this.firstItem = firstItem;
      if (rangeEnd !== 100) {
        this.rangeEnd = rangeEnd;
      }
      if (data.category !== this.getSelectedCategory) {
        this.firstItem = 0;
        this.rangeEnd = 100;
        this.setSelectedCategory(data.category);
      }

      this.onIsSearchingParts(true);
      if (this.firstItem == 0) {
        this.partSearch.push({
          label: data.description || data.category,
          code: data.category,
          command: this.handleCommand,
        });
      }
      this.currentSearch = "";
      this.selectedTreeCategories = { [this.getSelectedCategory]: true };

      this.callFetchPrices()
        .then((resp: any) => {
          this.totalRecords = resp.total_records_found
            ? parseInt(resp.total_records_found) + 1
            : 0;
          this.parts = resp.parts;
          this.updateParts();
          if (resp.filters) {
            this.setControlFilters(resp.filters);
          }
        })
        .finally(() => {
          this.isSearchingParts = false;
        });
    },
    callFetchPrices(cache = false): Promise<any> {
      return this.fetchPrices({
        code: this.codeArray,
        cat: this.getSelectedCategory,
        web_cats: this.getActualFilters(),
        client: this.getClient,
        status: this.posPartStatus,
        fieldnameFilters: JSON.stringify(this.searchOptions),
        part_no: this.currentSearch,
        customerId: this.getCustomer.cust_id,
        rangeStart: this.rangeStart,
        rangeEnd: this.rangeEnd,
        metaData: this.priceMetaData(),
        shipSequence: this.shippingAddress?.ship_seq,
        customFields: this.getSalesOrder?.custom_fields,
        cache,
        coCode: this.getSalesOrder?.co_code,
      });
    },
    clearFilters(search?: boolean) {
      this.selectedTreeCategories = null;
      this.partSearch = [];
      if (this.currentScreenSize === "sm") {
        this.selectedSmallFilterOptions = null;
      } else {
        this.clearSelectedFiltersOptions();
      }
      this.setSelectedCategory("");
      //call price
      if (search) {
        this.onIsSearchingParts(true);
        this.callFetchPrices()
          .then((resp: any) => {
            this.parts = resp.parts;
            this.totalRecords = resp.total_records_found
              ? parseInt(resp.total_records_found) + 1
              : 0;
            this.updateParts();
            if (resp.filters) {
              this.setControlFilters(resp.filters);
            }
          })
          .finally(() => {
            this.isSearchingParts = false;
          });
      }
    },
    clearCategory() {
      this.selectedTreeCategories = null;
      this.partSearch = [];
      this.setSelectedCategory("");
      this.clearSelectedFiltersOptions();
      this.initFilters();
    },
    getFilteredParts() {
      let filters = this.getActualFilters();
      this.onIsSearchingParts(true);
      this.callFetchPrices()
        .then((resp: any) => {
          this.totalRecords = resp.total_records_found
            ? parseInt(resp.total_records_found) + 1
            : 0;
          this.parts = resp.parts;
          this.updateParts();
          if (resp.filters) {
            this.setControlFilters(resp.filters);
          }
        })
        .finally(() => {
          this.isSearchingParts = false;
        });
    },
    initFilters() {
      const copy = cloneDeep(this.getSelectedFiltersOptions);
      const newFilters = [] as any[];
      this.getFilters.forEach((elem: any) => {
        const exists = copy.find((e: any) => e.label === elem.label);
        if (!exists) {
          newFilters.push({ label: elem.label, value: null });
        } else {
          newFilters.push({ label: elem.label, value: exists.value });
        }
      });
      this.setSelectedFiltersOptions(newFilters);
    },
    resizeFilters(event: any) {
      this.windowWidth = window.innerWidth;
      if (
        event.target.innerWidth > BREAKPOINT &&
        this.currentScreenSize !== "lg"
      ) {
        this.currentScreenSize = "lg";
        this.showPos = true;
        let filtered = [] as { label: string; value: any }[];
        if (this.selectedSmallFilterOptions !== null) {
          Object.entries(this.selectedSmallFilterOptions).forEach(
            ([key, value]) => {
              filtered.push({ label: key, value: value });
            },
          );
        }
        filtered = filtered.filter((elem) => elem.value.checked === true);
        let filterChange = {} as any;
        filtered.forEach((elem: any) => {
          this.getFilters.forEach((cat: any) => {
            let options = cat.tags.filter((opt: any) => opt.tag === elem.label);
            if (options.length > 0) {
              if (filterChange[cat.label] === undefined) {
                filterChange[cat.label] = { label: cat.label, value: options };
              } else {
                filterChange[cat.label].value.push(options[0]);
              }
            }
          });
        });
        filterChange = Object.values(filterChange);
        this.getSelectedFiltersOptions.forEach((elem: any) => {
          let index = filterChange.findIndex(
            (item: any) => item.label === elem.label,
          );
          if (index !== -1) {
            elem.value = filterChange[index].value;
          } else {
            elem.value = null;
          }
        });
      } else if (
        event.target.innerWidth <= BREAKPOINT &&
        this.currentScreenSize !== "sm"
      ) {
        this.currentScreenSize = "sm";
        let filtered = this.getSelectedFiltersOptions.filter(
          (elem: any) => elem.value !== null,
        );
        this.selectedSmallFilterOptions = {};
        filtered.forEach((elem: any) => {
          if (elem.value !== null) {
            elem.value.forEach((tag: any) => {
              this.selectedSmallFilterOptions[tag.tag] = {
                checked: true,
                partialSelected: false,
              };
            });
          }
        });
      }
    },
    getSmallFilterSelectedOptions() {
      let filtered = [] as { label: string; value: any }[];
      if (this.selectedSmallFilterOptions !== null) {
        Object.entries(this.selectedSmallFilterOptions).forEach(
          ([key, value]) => {
            filtered.push({ label: key, value: value });
          },
        );
      }
      filtered = filtered.filter((elem) => elem.value.checked === true);
      let filterSelection = [] as any;
      this.smallFilterOptionsComputed.forEach((tagcat) => {
        let tagByCat = [] as any;
        filtered.forEach((tag) => {
          if (tagcat.children.find((e: any) => e.key === tag.label)) {
            tagByCat.push(tag.label);
          }
        });
        filterSelection.push(tagByCat);
      });
      return filterSelection;
    },
    getLargeFilterSelectedOptions() {
      const filtered = this.getSelectedFiltersOptions.filter(
        (elem: any) => elem.value !== null,
      );
      let selectedOptions = [] as any;
      filtered.forEach((elem: any) => {
        let tagByCat = [] as any;
        if (elem.value !== null) {
          elem.value.forEach((tag: any) => {
            tagByCat.push(tag.tag);
          });
        }
        selectedOptions.push(tagByCat);
      });
      return selectedOptions;
    },
    getActualFilters() {
      if (this.currentScreenSize === "sm") {
        return this.getSmallFilterSelectedOptions();
      } else if (this.currentScreenSize === "lg") {
        return this.getLargeFilterSelectedOptions();
      }
    },
    onRowClick(event: any) {
      this.selectedProduct = event.data;
      this.showProductDetails = true;
    },
    closeDialog() {
      this.showProductDetails = false;
    },
    addRackPart(part: any) {
      rackService
        .getRackPrice(
          this.getClient,
          this.getCustomer.cust_id,
          part.part_id,
          part.current_count,
        )
        .then((resp: any) => {
          if (!resp.rack_items || resp.rack_items.length === 0) {
            return;
          }

          this.getSalesOrder.lis_items = this.getSalesOrder.lis_items.filter(
            (item: any) => item.li_parts !== part.part_id,
          );
          this.addRack({
            rack_count: part.current_count.toString(),
            part_id: part.part_id,
          });

          resp.rack_items.forEach((rack: any) => {
            const id = Utils.getNewSalesOrderLineItemId(this.getSalesOrder);
            this.getSalesOrder.lis_items.push({
              lis: id,
              li_parts: part.part_id,
              li_order_qtys: "1",
              li_prices: rack.rack_rate,
              li_discs: this.getCustomer.disc || "0",
              li_disc_amts: "",
              li_taxable: rack.taxable,
              wrap_desc: rack.rack_desc,
              li_sched_dates_items: [
                {
                  li_sched_dates: this.getSalesOrder.date,
                  li_sched_qtys: "1",
                },
              ],
              sell_qty: "1",
            });
          });
        })
        .finally(() => {
          part.loading = false;
        });
    },
    lookupPrice(input: any, searchComponent: any, event?: any) {
      if (this.getPosClearCategoryOnSearch) {
        this.clearCategory();
      }

      if (this.getSelectedCategory) {
        this.firstItem = 0;
        this.rangeEnd = 100;
      }
      if (input) {
        const partSearch = input;
        this.currentSearch = input;
        let code: string[] = [];
        if (this.getCustomer.code) {
          code.push(this.getCustomer.code);
        }
        if (this.getCustomer.cust_id !== this.getCustomer.code) {
          code.push(this.getCustomer.cust_id);
        }
        let fieldnameFilters = "";
        fieldnameFilters = JSON.stringify(this.searchOptions);
        const cache = this.partSearchCache.find(
          (e) => e.partSearch === partSearch,
        );
        if (cache) {
          cache.count++;
          if (cache.value && cache.count === 1) {
            searchComponent.$emit("priceSearch", {
              search: partSearch,
              parts: cache.value,
              isScanned: event?.isScanned ? event?.isScanned : false,
            });
          }
          return;
        }

        this.partSearchCache.push({
          partSearch: partSearch,
          count: 1,
          value: null,
        });

        this.callFetchPrices(true)
          .then((response: any) => {
            this.totalRecords = response.total_records_found
              ? parseInt(response.total_records_found) + 1
              : 0;
            searchComponent.$emit("priceSearch", {
              search: partSearch,
              parts: response.parts,
              isScanned: event?.isScanned ? event?.isScanned : false,
            });
          })
          .finally(() => {
            (searchComponent.$refs.autocomplete as any).searching = false;
            this.searchReference = searchComponent;
          });
      } else {
        this.isSearchingParts = false;
      }
    },
    handleOnHandTagText(availQty: number) {
      if (availQty > 0) {
        return `${availQty}`;
      } else {
        return "Out of Stock";
      }
    },
    handleOnHandTagColor(availQty: number) {
      return availQty > 0 ? "success" : "danger";
    },
    toggleQuickScan() {
      this.setQuickScan(!this.getQuickScan);
      this.$nextTick(() => {
        this.autoFocusInput();
      });
    },
    autoFocusInput() {
      const priceSearch = this.$refs.priceSearchText as any;
      priceSearch.$refs.autocomplete.$refs.focusInput.focus();
    },
    onPage(event: any) {
      this.firstItem = event.first;
      if (
        (event.page === event.pageCount ||
          event.page === event.pageCount - 1) &&
        this.parts.length == this.rangeEnd
      ) {
        this.rangeEnd += 100;
        this.onIsSearchingParts(true);
        this.totalRecords = 0;

        if (this.currentSearch !== "" && this.getSelectedCategory === "") {
          this.lookupPrice(this.currentSearch, this.searchReference);
        } else if (
          this.currentSearch === "" &&
          this.getSelectedCategory !== ""
        ) {
          const category = { category: this.getSelectedCategory };
          this.searchCategory(category, this.firstItem, this.rangeEnd);
        } else {
          const data = { code: this.codeArray, category: "" };
          this.searchCategory(data, this.firstItem, this.rangeEnd);
        }
      }
    },
    handleCategoriesChange(event: any) {
      const keys = Object.keys(event);
      let category = "";
      if (keys.length > 0) {
        category = keys[0];
      } else {
        category = "";
      }

      this.setSelectedCategory(category);

      // Need to rebuild the path to get the breadcrumbs working
      const flatten = (arr: any) => {
        return arr.reduce((acc: any, item: any) => {
          acc[item.key] = item.path;
          if (item.children) {
            acc = { ...acc, ...flatten(item.children) };
          }
          return acc;
        }, {});
      };

      const pathMap = flatten(this.treeSelectCategories);
      const path = pathMap[category];

      this.partSearch = [];
      let obj: any = this.getCats;
      let catInfo = {} as any;

      // Build the breadcrumbs
      path.forEach((cat: any) => {
        catInfo = obj.find((e: any) => e.category === cat);
        obj = catInfo.subcategories;

        this.partSearch.push({
          label: catInfo.description || cat,
          code: cat,
          command: this.handleCommand,
        });
      });
      this.getFilteredParts();
    },
    getCategoryChildren(category: string) {
      // Need to rebuild the path to get the breadcrumbs working
      const flatten = (arr: any) => {
        return arr.reduce((acc: any, item: any) => {
          acc[item.category] = item;
          if (item.subcategories) {
            acc = { ...acc, ...flatten(item.subcategories) };
          }
          return acc;
        }, {});
      };

      const pathMap = flatten(this.getCats);
      return pathMap[category];
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      totalRecords: 0,
      showPos: true,
      searchReference: null as any,
      rangeStart: 1,
      rangeEnd: 100,
      firstItem: 0,
      rows: 25,
      selectedTreeCategories: null as any,
      partIdFields: [] as any[],
      filterCategories: "",
      showCategoriesDialog: false,
      currentScreenSize: "",
      racks: [] as any[],
      showRacksButton: false,
      selectedSmallFilterOptions: {} as any,
      showProductDetails: false,
      selectedProduct: {} as any,
      isSearchingParts: false,
      isResultsListView: true,
      expandedRows: [] as Array<string>,
      partSearch: [] as any,
      currentSearch: "",
      filterInactive: true,
      searchOptions: {} as any,
      displayRacks: false,
      parts: [] as Array<any>,
      partSearchCache: [] as {
        partSearch: string;
        count: number;
        value: any | null;
      }[],
      filters: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      responsiveOptions: [
        {
          breakpoint: "1540px",
          numVisible: 10,
          numScroll: 2,
        },
        {
          breakpoint: "1199px",
          numVisible: 10,
          numScroll: 2,
        },
        {
          breakpoint: "870px",
          numVisible: 6,
          numScroll: 2,
        },
        {
          breakpoint: "700px",
          numVisible: 4,
          numScroll: 2,
        },
        {
          breakpoint: "525px",
          numVisible: 2,
          numScroll: 2,
        },
      ],
      modifiers: [
        {
          label: "PO Number",
          code: "PO#",
          callback: this.showPONumberDialog,
        },
        {
          label: "Order Type",
          code: "POT",
          callback: this.showPOTypeDialog,
        },
        {
          label: "Signature",
          code: "SGNT",
          callback: this.showSignatureDialog,
        },
      ],
    };
  },

  watch: {
    "searchReference.input": {
      handler: function (val) {
        if (val) {
          this.firstItem = 0;
          this.rangeEnd = 100;
        }
      },
      deep: true,
    },
    getFilters: {
      handler: function (val, oldVal) {
        this.initFilters();
      },
      deep: true,
    },

    currentCat: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.firstItem = 0;
          this.rangeEnd = 100;
        }
      },
      deep: true,
    },
  },
});


import { defineComponent, PropType } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Lookup from "@/types/lookup";
import LookupService from "@/services/LookupService";
import Utils from "@/utility/utils";
import { Field } from "@/types/fdict";
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";

const lookupService = new LookupService();

enum ConversionTypes {
  DATE = "D",
  NUMERIC_NO_JUSTIFICATION = "MD",
  NUMERIC_LEFT = "ML",
  NUMERIC_RIGHT = "MR",
}

export default defineComponent({
  components: {
    DataTable,
    Calendar,
    Column,
    InputText,
  },
  props: {
    lookup: {
      type: Object as PropType<Lookup>,
      required: true,
    },
    params: {
      type: Object as PropType<any>,
      required: false,
    },
  },
  data() {
    return {
      data: [] as any[],
      columns: [] as Field[],
      loading: false,
      filters: {} as any,
    };
  },
  async created() {
    this.loading = true;

    try {
      const lookupData = await lookupService.fetchLookupData({
        lookupName: this.lookup.lookupName,
        recordId: this.lookup.recordId,
        file: this.lookup.file,
        parameters: this.params,
      });
      this.data = lookupData.data;
      lookupData.fDicts.forEach((field: Field) => {
        this.columns.push({
          ...field,
          display_name: Utils.formatDictionaryName(field.dict_name),
        });
        this.filters[field.dict_name] = {
          value: null,
          matchMode: this.isDictDateType(field)
            ? FilterMatchMode.BETWEEN
            : FilterMatchMode.CONTAINS,
        };
        if (this.isDictDateType(field) && this.data.length > 0) {
          this.data = this.data.map((row) => {
            return {
              ...row,
              [field.dict_name]: !row[field.dict_name]
                ? ""
                : new Date(row[field.dict_name]),
            };
          });
        }
        if (this.isDictNumeric(field) && this.data.length > 0) {
          this.data = this.data.map((row) => {
            return {
              ...row,
              [field.dict_name]: !row[field.dict_name]
                ? 0
                : +row[field.dict_name],
            };
          });
        }
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    isDictNumeric(field: Field): boolean {
      return (
        this.checkDictType(field, ConversionTypes.NUMERIC_LEFT) ||
        this.checkDictType(field, ConversionTypes.NUMERIC_NO_JUSTIFICATION) ||
        this.checkDictType(field, ConversionTypes.NUMERIC_RIGHT)
      );
    },
    isDictDateType(field: Field): boolean {
      return this.checkDictType(field, ConversionTypes.DATE);
    },
    checkDictType(field: Field, type: ConversionTypes): boolean {
      if (!field.conv) {
        return false;
      }
      return field.conv?.toUpperCase().startsWith(type);
    },
  },
});
